import request from '@/utils/request';
import store from '../store';

// 获取商户系统设置接口
export function getUserSystemSetupAPI(id) {
  return request({
    url: `/organization/setting/${id}`,
    method: 'get'
  });
}

// /h5/client-userMembers
export function getUserMemberList(id) {
  return request({
    url: `/h5/client-userMembers/?parentId=${id}`,
    method: 'get'
  });
}

// /h5/client-userMember/{id}
export function getUserMemberInfo(id) {
  return request({
    url: `/h5/client-userMember/${id}`,
    method: 'get'
  });
}

// /organization/client-userMembers
export function AddUserMember(data) {
  return request({
    url: `/h5/client-userMembers`,
    method: 'post',
    data
  });
}

export function removeUserMember(id) {
  return request({
    url: `/h5/client-userMembers/remove/${id}`,
    method: 'post'
  });
}

// /upload/image
export function uploadUserMemberImage(data) {
  return request({
    url: `/upload/image`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'blob',
    data
  });
}


// 家属申请记录 /h5/client-userMembersVerify
export function getUserMemberVerifyList(id) {
  return request({
    url: `/h5/client-userMembersVerify/?parentId=${id}`,
    method: 'get'
  });
}