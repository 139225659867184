<template>
  <div class="container">
    <TopHeader :title="titleName" returnPath="FamilyList"></TopHeader>
    <div style="flex: 1;width: 100%;overflow: auto;">
      <van-form ref="form" @submit="onSubmit" >
        <div style="height: 190px;background: #fff;margin-bottom: 15px;display: flex;flex-direction: column;align-items: center;justify-content: center;position: relative;">
          <div style="height: 140px;width: 140px;position: relative;" @click="handlerShowOperate">
            <img :src="form.photo?`${apiUrl}/image?name=${form.photo}`:none" alt style="background: #E5E5E5;height: 100%;width: 100%;border-radius: 50%;" />
            <div style="position: absolute;bottom: 5px;height: 40px;width: 40px;background: #fff;right: 5px;display: flex;justify-content: center;align-items: center;border-radius: 50%;">
              <img style="height: 30px;width: 30px;" :src="camera" alt >
            </div>
          </div>
          <div class="select-content" v-if="showOperate" style="background: #f7f7f7;position: absolute;width: 90px;display: flex;flex-direction: column;align-items: center;justify-content: center;border: 1px solid #f7f7f7;box-sizing: border-box;right: 30px;">
            <div v-if="!isAndroid">
              <div style="box-sizing: border-box;padding: 8px;border-bottom: 1px solid #ccc;" @click="handler">
                <div>上传人脸</div>
              </div>
              <div v-show="false">
                <van-uploader
                  ref="uploader"
                  v-model="fileList"
                  :after-read="(file) => onLoadCard(file, 'front')"
                  :max-count="1"
                  :max-size="50 * 1024 * 1024"
                  :preview-image="false"
                  @delete="onRemove"
                />
              </div>
            </div>
            <div v-if="isAndroid" class="items">
              <div style="box-sizing: border-box;padding: 8px;border-bottom: 1px solid #ccc;" @click="handlerImage">
                <div>拍照上传</div>
              </div>
              <div v-show="false">
                <van-uploader
                  ref="uploaderImage"
                  v-model="fileList"
                  :after-read="(file) => onLoadCard(file, 'front')"
                  :max-count="1"
                  :max-size="50 * 1024 * 1024"
                  :preview-image="false"
                  @delete="onRemove"
                />
              </div>
            </div>
            <div v-if="isAndroid" class="items">
              <div style="box-sizing: border-box;padding: 8px;border-bottom: 1px solid #ccc;" @click="handlerAlbum">
                <div>相册选择</div>
              </div>
              <div v-show="false">
                <van-uploader
                  ref="uploaderAlbum"
                  v-model="fileList"
                  accept="album/*"
                  :after-read="(file) => onLoadCard(file, 'front')"
                  :max-count="1"
                  :max-size="50 * 1024 * 1024"
                  :preview-image="false"
                  @delete="onRemove"
                />
              </div>
            </div>
            <div v-if="hasPhoto" style="color: #f00;box-sizing: border-box;padding: 8px;border-bottom: 1px solid #ccc;" @click="handldDeleteImage">删除人脸</div>
          </div>
        </div>
        <div style="background: #fff;">
          <div style="height: 45px;display: flex;flex-direction: row;align-items:center;justify-content: space-between;box-sizing: border-box;padding: 0 16px;">
            <div style="width: 60px;color: #333;">姓名</div>
            <div style="width: 100px;color: #666;">
              <van-field
                v-model="form.name"
                placeholder="姓名"
                maxlength="20"
              />
            </div>
          </div>
          <div style="box-sizing: border-box;padding-left: 16px;height: 1px;width: 100%;">
            <div style="height: 1px;background: #e5e5e5;"></div>
          </div>
          <div style="height: 45px;display: flex;flex-direction: row;align-items:center;justify-content: space-between;box-sizing: border-box;padding: 0 16px;">
            <div style="width: 60px;color: #333;">手机号</div>
            <div style="width: 150px;color: #666;">
              <van-field
                v-model="form.phone"
                placeholder="手机号"
              />
            </div>
          </div>
          <div style="box-sizing: border-box;padding-left: 16px;height: 1px;width: 100%;">
            <div style="height: 1px;background: #e5e5e5;"></div>
          </div>
          <div style="height: 45px;display: flex;flex-direction: row;align-items:center;justify-content: space-between;box-sizing: border-box;padding: 0 16px;">
            <div style="color: #333;">性别</div>
            <div style="font-size: 16px;color: #666;" @click="handleChangeGender">{{ form.gender === 0 ? '未知' : form.gender === 1 ? '男' : form.gender === 2 ? '女' : '' }}<van-icon name="arrow-down" style="margin-left: 2px;" /></div>

          </div>
          <div style="box-sizing: border-box;padding-left: 16px;height: 1px;width: 100%;">
            <div style="height: 1px;background: #e5e5e5;"></div>
          </div>
        </div>
        <div style="margin: 16px;">
          <van-button style="background: #FA5050;color: #fff;font-size: 18px;" block native-type="submit">确认</van-button>
        </div>
      </van-form>

      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          title="标题"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
      
      
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import camera from '@/assets/images/camera.png'
import none from "@/assets/photo_none.png";
import { Toast } from "vant";
import store from '@/store'
import { getUserMemberInfo, AddUserMember, uploadUserMemberImage } from '@/api/family'
import axios from 'axios'
import TopHeader from '@/components/topHeader/index.vue'
export default {
  components: {
    TopHeader
  },
    data() {
      return {
        cancel,
        camera,
        none,
        title: '',
        titleName: '',
        apiUrl: process.env.VUE_APP_BASE_API,
        headers: {
          Authorization: "Bearer " + store.getters.token,
        },
        memberId: null,
        form: {
          gender: 0,
        },
        showPicker: false,
        showOperate: false,
        columns: ['未知', '男', '女'],
        fileList: [],
        imageUrl: "",
        hasPhoto: false,
        oldForm: {}
      };
    },

    created() {
      const { id } = this.$route.query
      if (id) {
        this.title = '编辑家属账号'
        this.titleName = '编辑家属账号'
        this.memberId = id
        this.getUserMemberDetail(id)
      } else {
        this.title = '添加家属账号'
        this.titleName = '添加家属账号'
      }
    },

    computed: {
      isAndroid() {
        return navigator.userAgent.indexOf("Android") > -1;
      },
    },

    methods: {
      toHome() {
        this.$router.back();
      },

      async getUserMemberDetail(id) {
        const res = await getUserMemberInfo(id)
        if (res.code === 0) {
          this.form = res.data
          this.oldForm = JSON.parse(JSON.stringify(res.data))
        }
      },

      handleChangeGender() {
        this.showPicker = true;
      },

      handlerShowOperate() {
        this.$set(this, 'showOperate', true)
        this.hasPhoto = this.form.photo ? true : false
      },

      handleAvatarSuccess(res, file) {
        this.showOperate = false
        if(res.code === 0){
          this.$set(this.form, 'photo', res.data.name)
        } else {
          Toast.fail(res.message)
        }
      },

      beforeAvatarUpload(file, type) {
        const isJPG =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg";
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isJPG) {
          this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("单张图片大小不能超过 10MB!");
        }
        this.showOperate = false
        return isJPG && isLt2M;
      },

      // 人脸绑定（拍照）
      handlerImage() {
        if (this.fileList.length !== 1) {
          this.$refs.uploaderImage.chooseFile();
        } else {
          this.fileList = [];
          this.$nextTick(() => this.handlerImage());
        }
      },

      onRemove() {
        this.fileImg = "";
      },

      handldDeleteImage() {
        this.$set(this.form, 'photo', '')
        this.showOperate = false
      },

       // 人脸绑定
      handler() {
        if (this.fileList.length !== 1) {
          this.$refs.uploader.chooseFile();
        } else {
          this.fileList = [];
          this.$nextTick(() => this.handler());
        }
      },

      // 人脸绑定（拍照）
      handlerImage() {
        if (this.fileList.length !== 1) {
          this.$refs.uploaderImage.chooseFile();
        } else {
          this.fileList = [];
          this.$nextTick(() => this.handlerImage());
        }
      },

      // 人脸绑定（相册）
      handlerAlbum() {
        if (this.fileList.length !== 1) {
          this.$refs.uploaderAlbum.chooseFile();
        } else {
          this.fileList = [];
          this.$nextTick(() => this.handlerAlbum());
        }
      },

      async onLoadCard(file) {
        const imgData = new FormData()
        imgData.append('photo', file.file)
        axios.defaults.headers.common['Authorization'] = "Bearer " + store.getters.token
        axios.post( process.env.VUE_APP_BASE_API + '/upload/image', imgData ).then((res) => {
            if(res.data.code === 0){
              this.$set(this.form, 'photo', res.data.data.name)
            } else {
              Toast.fail(res.data.message)
            }
          })
          .catch(err => {
            console.log(err)
          })
      },

      onConfirm(value, index) {
        this.showPicker = false;
        this.form.gender = index
      },

      onCancel() {
        this.showPicker = false;
      },

      handleCompare(obj1, obj2){
        return _.isEqual(obj1, obj2);
      },

      async onSubmit() {
        const organizationId = JSON.parse(sessionStorage.getItem("personInfo")).organizationId;
        const userId = store.getters.userId;
        if (!this.form.name || !this.form.phone) {
          Toast.fail('请填写完整信息!')
          return
        }
        const reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
        if(!reg.test(this.form.phone)){
          Toast.fail('手机号格式错误!')
          return
        }
        const data = {
          organizationId,
          ...this.form,
          parentId: userId
        };

        const _ = require('lodash');

        if(this.handleCompare(this.form, this.oldForm)){
          this.$router.push({ name: 'FamilyList' });
        }else{
          const res = await AddUserMember(data)
          if (res.code === 0) {
            Toast.success(this.memberId ? '申请已提交' : '添加成功')
            this.$router.push({ name: 'FamilyList' });
          }else{
            Toast.fail(res.message)
            this.$router.push({ name: 'FamilyList' });
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  & > .nav {
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
}

::v-deep .van-field__control{
  text-align: right;
  font-size: 16px;
  color: #666;
}

.select-content{

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid #f7f7f7;
    border-bottom: 10px solid transparent;
  }
}
</style>